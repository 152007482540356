import * as React from "react";
import styled from "styled-components";
import bg from "./assets/img/bg.png";
import loc from "./assets/img/loc.png";
import Name from "./Name";

const Banner = () => {
  // Set the initial date and time to Thursday, September 12th at 11:20 AM
  const initialDate = new window.Date("2024-09-12T11:20:00");
  // Add 29 days to the initial date
  const targetDate = new window.Date(initialDate);
  targetDate.setDate(initialDate.getDate());
  const calculateDaysLeft = () => {
    const now = new window.Date();
    const difference = targetDate - now;
    const daysLeft = Math.ceil(difference / (1000 * 60 * 60 * 24));
    return daysLeft;
  };
  const [daysLeft, setDaysLeft] = React.useState(calculateDaysLeft());
  React.useEffect(() => {
    const timer = setInterval(() => {
      setDaysLeft(calculateDaysLeft());
    }, 1000 * 60 * 60 * 24); // Update every 24 hours
    return () => clearInterval(timer);
  }, []);

  return (
    <Wrapper>
      <Row>
        <ColLeft className="ColLeft"></ColLeft>
        <ColRight className="ColRight">
          <DateWrapper>
            <Date>
              Thursday 12 september <span>11:20 am</span>
            </Date>
            <Day>
              {daysLeft > 0
                ? `${daysLeft} days to go`
                : `${Math.abs(daysLeft)} Days ago`}
            </Day>
          </DateWrapper>
          <SvgBlock>
            <Name className="name" />
          </SvgBlock>
          <Block className="Block">
            <ButtonWrapper
              target="_blank"
              href="https://www.google.com/maps/place/Grace+Convention+Centre/@9.2264655,76.5367652,21z/data=!4m10!1m2!2m1!1sGrace+Convention+Centre!3m6!1s0x3b061f5acc17872f:0xa2e929e2a2399759!8m2!3d9.2263376!4d76.5368289!15sChdHcmFjZSBDb252ZW50aW9uIENlbnRyZZIBEWNvbnZlbnRpb25fY2VudGVy4AEA!16s%2Fg%2F11ghfth35l?hl=en-IN&entry=ttu"
              className="ButtonWrapper"
            >
              <Img src={loc} />
              Get Direction
            </ButtonWrapper>
            <Para className="Para">
              Grace Convention Centre<br></br>Laha Junction, Punnamoodu,
              Mavelikkara
            </Para>
          </Block>
        </ColRight>
      </Row>
    </Wrapper>
  );
};
export default Banner;
const Wrapper = styled.div`
  background-color: #f2f2f0;
  align-items: center;
  position: relative;
  min-height: 100dvh;
  display: flex;
  &::before {
    background-image: url(${bg});
    background-repeat: no-repeat;
    background-position: top left;
    background-size: cover;
    position: absolute;
    height: 110%;
    width: 100%;
    content: "";
    z-index: 0;
    top: -10%;
  }
  @media (min-width: 300px) and (max-width: 575px) {
    &::before {
      opacity: 0.5;
      width: 200%;
      top: -10%;
      left: 0;
    }
    .ColLeft,
    .ColRight {
      width: 100%;
    }
    .ColRight {
      padding: 10vh 0 0 0;
      text-align: center;
    }
    .name {
      max-width: 225px;
      margin: 0 auto;
      display: block;
    }
    .ButtonWrapper {
      padding: 7px 16px 7px 12px;
      font-size: 12px;
    }
    .Block {
      display: block;
    }
    .Para {
      padding: 35px 20px 0 20px;
      position: relative;
      border: none;
      margin: 0;
      &::before {
        transform: translateX(-50%);
        background: #a7b59b;
        position: absolute;
        content: "";
        width: 45px;
        height: 1px;
        top: 20px;
        left: 50%;
      }
    }
  }
`;
const Row = styled.div`
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  display: flex;
  height: 100%;
  width: 100%;
`;
const ColLeft = styled.div``;
const ColRight = styled.div``;
const DateWrapper = styled.div`
  position: absolute;
  text-align: left;
  right: 10%;
  top: 10%;
`;

const Date = styled.div`
  text-transform: uppercase;
  font-size: clamp(1rem, 1.2vw, 1.4vw);
  font-weight: 700;
  color: #0e9aa8;
`;
const Day = styled.div`
  font-size: clamp(1rem, 1.2vw, 1.4vw);
  font-weight: 500;
  color: #909d84;
`;
const SvgBlock = styled.div`
  padding: 0 0 6vh 0;
  position: relative;
  @media (min-height: 100px) and (max-height: 600px) {
    svg {
      max-height: 40vh;
    }
  }
`;
const Img = styled.img`
  margin: 0 5px 0 0;
`;
const ButtonWrapper = styled.a`
  border: solid 1px #a7b59b;
  padding: 9px 25px 9px 20px;
  letter-spacing: 0.4px;
  text-decoration: none;
  display: inline-flex;
  border-radius: 30px;
  align-items: center;
  color: #909d84;
  font-weight: 600;
  cursor: pointer;
  &:hover {
    background: #909d84;
    color: #ffffff;
    img {
      filter: brightness(2);
    }
  }
`;
const Block = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
`;
const Para = styled.p`
  border-left: solid 1px #a7b59b;
  letter-spacing: 0.2px;
  padding: 0 0 0 20px;
  margin: 0 0 0 20px;
  color: #909d84;
  font-weight: 500;
`;
