import React from "react";
import styled from "styled-components";
const Name = ({ className }) => {
  return (
    <SvgWrapper
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 257.66 147.56"
      style={{ enableBackground: "new 0 0 257.66 147.56" }}
      xmlSpace="preserve"
      className={className}
    >
      <style type="text/css">
        {`
            .st0{fill:#0E9AA8;}
            .st1{opacity:0.6;}
            .st2{fill:#F27CD4;}
          `}
      </style>
      <g>
        <path
          className="st0"
          d="M42.89,48.36c0.68,5.93-2.38,11.67-7.69,14.41c-1.81,0.98-3.75,1.71-5.76,2.18c-1.53,0.37-3.09,0.61-4.66,0.74
		l-0.25,0.02c-0.25,0.02-0.5,0.04-0.75,0.05c-0.57,0.04-1.13,0.06-1.69,0.06h-0.07c-2.46,0.02-4.92-0.19-7.35-0.62
		c-4.6-0.83-8.87-2.94-12.33-6.07c-0.34-0.4-0.49-0.92-0.42-1.44c0.07-0.52,0.35-0.98,0.78-1.28c0.57-0.51,1.21-0.95,1.9-1.29
		c1.1-0.55,2.11-0.56,2.45,0.04c2.01,3.32,4.92,6,8.38,7.75c2.06,1.05,4.34,1.6,6.65,1.6c0.06,0,0.2,0,0.26,0
		c2.33-0.01,4.65-0.37,6.87-1.08c1.78-0.54,3.45-1.39,4.94-2.5c2.42-1.74,3.98-4.42,4.3-7.38c0.15-1.44,0.02-2.89-0.38-4.28
		c-0.22-0.74-0.52-1.45-0.9-2.13c-0.2-0.35-0.42-0.69-0.66-1.02c-0.23-0.31-0.49-0.61-0.77-0.88c-1.67-1.45-3.54-2.66-5.54-3.6
		c-2.52-1.32-5.19-2.64-8.52-4.2c-3.06-1.39-6.02-3-8.85-4.82c-2.4-1.54-4.54-3.44-6.34-5.65c-1.56-1.91-2.64-4.17-3.16-6.58
		c-0.4-2.05-0.31-4.18,0.26-6.19c1.04-3.6,3.34-6.71,6.49-8.75c3.23-2.14,7.52-3.41,13.05-3.41h0.04c2.49,0,4.96,0.33,7.35,1
		c2.23,0.65,4.32,1.7,6.17,3.1c1.9,1.44,3.57,3.15,4.96,5.08c0.25,0.46,0.29,1,0.12,1.49c-0.17,0.49-0.54,0.89-1.01,1.1
		c-0.67,0.38-1.39,0.66-2.14,0.85c-1.18,0.33-2.16,0.14-2.39-0.52c-1.16-3.63-3.52-6.76-6.68-8.89c-1.92-1.22-4.15-1.87-6.42-1.89
		h-0.01c-5.3,0-9.25,1.58-11.74,3.8c-2.28,1.98-3.68,4.77-3.9,7.78c-0.19,2.85,0.67,5.53,3.37,8.26c1.62,1.57,3.43,2.94,5.39,4.05
		c2.26,1.36,5,2.7,8.28,4.25c1.63,0.78,3.2,1.54,4.68,2.31c0.73,0.38,1.47,0.79,2.13,1.16l1.95,1.12c2.55,1.37,4.84,3.17,6.76,5.33
		c0.45,0.55,0.85,1.15,1.2,1.77c0.31,0.57,0.58,1.15,0.81,1.76C42.48,46.04,42.76,47.19,42.89,48.36L42.89,48.36z"
        />
        <path
          className="st0"
          d="M74.93,65.83l0.02-4.88c-1.21,0.92-2.5,1.74-3.86,2.43c-1.25,0.69-2.58,1.21-3.97,1.53
		c-0.9,0.26-1.82,0.46-2.74,0.61c-2.06,0.34-4.16,0.37-6.23,0.1c-2.14-0.26-4.19-1-6-2.17l-0.72-0.5c-0.23-0.19-0.45-0.4-0.68-0.61
		c-0.47-0.43-0.9-0.91-1.27-1.43c-0.4-0.56-0.75-1.16-1.03-1.79c-0.25-0.59-0.44-1.2-0.6-1.82c-0.61-2.4-0.43-4.93,0.51-7.21
		c0.87-2.04,2.26-3.83,4.04-5.16c1.75-1.26,3.67-2.26,5.7-2.97c2.1-0.8,4.24-1.46,6.43-1.96c9.61-2.3,9.97-5.48,9.97-5.51h0.09
		c-0.09-0.48-0.19-0.93-0.31-1.34c-0.29-1.14-0.74-2.23-1.32-3.25c-0.57-0.99-1.37-1.83-2.32-2.47c-1.36-0.84-2.93-1.27-4.53-1.23
		h-0.04c-1.98,0-3.91,0.63-5.52,1.78c-2.54,2.04-4.08,5.06-4.22,8.31c-0.05,0.59-0.94,0.9-2.17,0.82c-0.77-0.03-1.53-0.14-2.28-0.34
		c-1.17-0.35-1.85-1.36-1.48-2.16c0.89-2.01,2.13-3.85,3.67-5.42c1.5-1.54,3.35-2.69,5.39-3.36c2.15-0.65,4.39-0.97,6.64-0.95
		c1.79,0,3.57,0.18,5.32,0.56c1.45,0.3,2.85,0.81,4.16,1.51c1.58,0.88,2.91,2.15,3.87,3.69c1.4,2.32,2.12,4.98,2.07,7.69v20.79
		c0,2.45,1.02,4.79,2.82,6.45l0.26,0.24L74.93,65.83z M70.48,62.2c1.62-0.79,3.12-1.8,4.47-2.99V38.34
		c-0.01-0.65-0.04-1.24-0.09-1.79c-1.2,1.41-3.91,3.28-10.01,4.74c-4.65,1.24-7.92,3.67-9.59,6.38c-0.76,1.22-1.22,2.61-1.37,4.04
		c-0.12,1.35-0.01,2.71,0.34,4.02c0.06,0.36,0.2,0.68,0.28,1.01c0.13,0.23,0.15,0.5,0.31,0.74c0.12,0.27,0.27,0.53,0.44,0.77
		l0.26,0.4l0.32,0.38c0.97,1.15,2.15,2.1,3.47,2.81c1.55,0.85,3.26,1.37,5.02,1.53C66.45,63.57,68.58,63.16,70.48,62.2L70.48,62.2z"
        />
        <path
          className="st0"
          d="M122.42,40.54v-0.05c-0.01-7.63-2.1-13.46-7.28-13.46c-3.47,0.06-6.78,1.43-9.28,3.83
		c-1.85,1.7-3.47,3.62-4.82,5.74c0.07,0.64,0.12,1.28,0.13,1.92v20.6c0,2.45,1.02,4.79,2.82,6.45l0.26,0.24H91.5l0.26-0.24
		c1.8-1.66,2.82-4,2.82-6.45v-20.6c-0.01-1.72-0.19-3.44-0.53-5.12c-0.21-1.13-0.53-2.23-0.98-3.29c-0.98-2.48-3.44-4.05-6.1-3.91
		v-1.32c1.74,0,3.47,0.24,5.14,0.73c1.38,0.41,2.69,1.03,3.89,1.83c1.41,0.98,2.57,2.27,3.39,3.77c0.61,1.11,1.07,2.3,1.34,3.54
		c3.44-4.89,9.07-9.87,15.4-9.87c1-0.02,2.01,0.05,3,0.21c0.86,0.15,1.7,0.41,2.49,0.77c1.42,0.66,2.69,1.62,3.71,2.81
		c2.04,2.36,3.61,5.78,3.67,11.87v18.59c0,2.45,1.02,4.79,2.82,6.45l0.26,0.24h-12.76l0.26-0.24c1.8-1.66,2.82-4,2.82-6.45
		L122.42,40.54L122.42,40.54z"
        />
        <path
          className="st0"
          d="M174.95,34.64c3.76,6.65,3.76,14.79,0,21.44c-1.23,2.11-2.89,3.94-4.86,5.38c-1.66,1.23-3.49,2.21-5.43,2.91
		c-1.84,0.69-3.77,1.14-5.73,1.34c-0.62,0.07-1.24,0.11-1.86,0.11H157c-0.62,0-1.25-0.04-1.86-0.11c-1.96-0.2-3.89-0.65-5.73-1.34
		c-1.94-0.7-3.77-1.69-5.43-2.91c-1.97-1.44-3.63-3.27-4.86-5.38c-3.76-6.65-3.76-14.79,0-21.44c1.23-2.11,2.89-3.94,4.86-5.37
		c1.66-1.23,3.49-2.21,5.43-2.92c1.84-0.69,3.77-1.14,5.73-1.34c0.63-0.08,1.26-0.11,1.9-0.11h0.01c0.63,0,1.27,0.04,1.9,0.11
		c1.96,0.2,3.88,0.65,5.73,1.34c1.94,0.7,3.77,1.69,5.43,2.92C172.06,30.7,173.72,32.53,174.95,34.64L174.95,34.64z M169.76,53.58
		c1.9-5.32,1.9-11.13,0-16.45c-0.62-1.8-1.45-3.51-2.49-5.1c-0.92-1.49-2.14-2.77-3.58-3.78c-1.96-1.33-4.28-2.04-6.65-2.04h-0.01
		l-0.14,0.01c-2.35,0.02-4.63,0.74-6.57,2.06c-1.42,1-2.63,2.28-3.54,3.76c-1.03,1.59-1.86,3.3-2.48,5.09
		c-1.9,5.32-1.9,11.13,0,16.45c0.62,1.8,1.45,3.51,2.49,5.11c0.92,1.49,2.14,2.77,3.58,3.78c1.96,1.33,4.27,2.04,6.64,2.04h0.03
		l0.13-0.01c2.34-0.02,4.63-0.74,6.57-2.06c1.42-1,2.63-2.28,3.54-3.76C168.31,57.09,169.14,55.38,169.76,53.58L169.76,53.58z"
        />
        <path
          className="st0"
          d="M190.61,31.23c1.2,2.24,1.81,4.75,1.78,7.3v37.17c0.06,2.76-0.57,5.49-1.81,7.95c-0.89,1.69-2.2,3.11-3.8,4.15
		c-1.33,0.84-2.81,1.44-4.35,1.76c-1.8,0.39-3.64,0.59-5.49,0.59h-0.11c-2.02,0.02-4.04-0.3-5.96-0.95
		c-1.89-0.67-3.57-1.84-4.85-3.39c-1.33-1.62-2.27-3.52-2.77-5.56c-0.2-0.8,0.63-1.56,1.84-1.69c0.76-0.08,1.54-0.09,2.3-0.01
		c1.23,0.13,2.06,0.57,1.99,1.08c-0.19,1.32-0.09,2.67,0.31,3.95c1,3.13,3.91,5.26,7.2,5.25c1.67,0.03,3.31-0.41,4.74-1.26
		c1.01-0.64,1.84-1.53,2.39-2.6c0.57-1.1,0.98-2.27,1.22-3.48c0.39-1.9,0.58-3.84,0.57-5.78V38.53c-0.01-1.72-0.19-3.44-0.53-5.12
		c-0.21-1.13-0.53-2.23-0.98-3.29c-0.98-2.48-3.44-4.05-6.1-3.91v-1.32c1.74,0,3.47,0.24,5.14,0.73c1.38,0.41,2.69,1.03,3.89,1.83
		C188.63,28.44,189.79,29.73,190.61,31.23z M185.81,21.6c0-1.82,1.47-3.29,3.29-3.29s3.29,1.47,3.29,3.29s-1.47,3.29-3.29,3.29
		S185.81,23.42,185.81,21.6z"
        />
        <path
          className="st0"
          d="M42.89,128.36c0.68,5.93-2.38,11.67-7.69,14.41c-1.81,0.98-3.75,1.71-5.76,2.18
		c-1.53,0.37-3.09,0.61-4.66,0.74l-0.25,0.02c-0.25,0.02-0.5,0.04-0.75,0.05c-0.57,0.04-1.13,0.06-1.69,0.06h-0.07
		c-2.46,0.02-4.92-0.19-7.35-0.62c-4.6-0.83-8.87-2.94-12.33-6.07c-0.34-0.4-0.49-0.92-0.42-1.44c0.07-0.52,0.35-0.98,0.78-1.28
		c0.57-0.51,1.21-0.95,1.9-1.29c1.1-0.55,2.11-0.56,2.45,0.04c2.01,3.32,4.92,6,8.38,7.75c2.06,1.05,4.34,1.6,6.65,1.6
		c0.06,0,0.2,0,0.26,0c2.33-0.01,4.65-0.37,6.87-1.08c1.78-0.54,3.45-1.39,4.94-2.5c2.42-1.74,3.98-4.42,4.3-7.38
		c0.15-1.44,0.02-2.89-0.38-4.28c-0.22-0.74-0.52-1.45-0.9-2.13c-0.2-0.35-0.42-0.69-0.66-1.02c-0.23-0.31-0.49-0.61-0.77-0.88
		c-1.67-1.45-3.54-2.66-5.54-3.6c-2.52-1.32-5.19-2.64-8.52-4.2c-3.06-1.39-6.02-3-8.85-4.82c-2.4-1.54-4.54-3.44-6.34-5.65
		c-1.56-1.91-2.64-4.17-3.16-6.58c-0.4-2.05-0.31-4.18,0.26-6.19c1.04-3.6,3.34-6.71,6.49-8.75c3.23-2.14,7.52-3.41,13.05-3.41h0.04
		c2.49,0,4.96,0.33,7.35,1c2.23,0.65,4.32,1.7,6.17,3.1c1.9,1.44,3.57,3.15,4.96,5.08c0.25,0.46,0.29,1,0.12,1.49
		c-0.17,0.49-0.54,0.89-1.01,1.1c-0.67,0.38-1.39,0.66-2.14,0.85c-1.18,0.33-2.16,0.14-2.39-0.52c-1.16-3.63-3.52-6.76-6.68-8.89
		c-1.92-1.22-4.15-1.87-6.42-1.89h-0.01c-5.3,0-9.25,1.58-11.74,3.8c-2.28,1.98-3.68,4.77-3.9,7.78c-0.19,2.84,0.67,5.53,3.37,8.26
		c1.62,1.58,3.43,2.94,5.39,4.05c2.26,1.36,5,2.69,8.28,4.25c1.63,0.78,3.2,1.54,4.68,2.31c0.73,0.38,1.47,0.79,2.13,1.16l1.95,1.12
		c2.55,1.37,4.84,3.17,6.76,5.33c0.45,0.55,0.85,1.15,1.2,1.77c0.31,0.57,0.58,1.15,0.81,1.76
		C42.48,126.04,42.76,127.19,42.89,128.36L42.89,128.36z"
        />
        <path
          className="st0"
          d="M78.94,120.54v-0.05c-0.01-7.63-2.1-13.46-7.28-13.46c-3.47,0.06-6.78,1.43-9.28,3.83
		c-1.85,1.7-3.47,3.62-4.82,5.74c0.07,0.64,0.12,1.28,0.13,1.92v20.6c0,2.45,1.02,4.79,2.82,6.45l0.26,0.24H48.01l0.26-0.24
		c1.8-1.66,2.82-4,2.82-6.45v-20.6c-0.01-1.72-0.19-3.44-0.53-5.12c-0.21-1.13-0.53-2.23-0.98-3.29c-0.98-2.48-3.44-4.05-6.1-3.91
		v-1.32c1.74,0,3.47,0.24,5.14,0.73c1.38,0.41,2.69,1.03,3.89,1.83c1.41,0.98,2.57,2.27,3.39,3.77c0.61,1.11,1.07,2.3,1.34,3.54
		c3.44-4.89,9.07-9.87,15.4-9.87c1-0.02,2.01,0.05,3,0.21c0.86,0.15,1.7,0.41,2.49,0.77c1.42,0.66,2.69,1.62,3.71,2.81
		c2.04,2.36,3.61,5.78,3.67,11.87v18.59c0,2.45,1.02,4.79,2.82,6.45l0.26,0.24H75.86l0.26-0.24c1.8-1.66,2.82-4,2.82-6.45
		L78.94,120.54L78.94,120.54z"
        />
        <path
          className="st0"
          d="M132.02,134.33l-0.19,0.63c-0.03,0.11-3.38,10.33-17.78,10.64v0.01h-0.54c-0.04,0-0.73,0-0.77,0v-0.02
		c-0.41-0.01-0.82-0.04-1.22-0.09c-1.95-0.19-3.86-0.63-5.7-1.31c-1.93-0.69-3.75-1.65-5.41-2.86c-1.97-1.42-3.62-3.22-4.86-5.31
		c-3.77-6.6-3.77-14.71,0-21.31c1.23-2.09,2.89-3.9,4.86-5.31c1.66-1.21,3.48-2.17,5.41-2.86c1.83-0.68,3.75-1.12,5.69-1.31
		c0.63-0.07,1.25-0.11,1.88-0.11h0.66v0.01c2.49,0.09,4.95,0.61,7.27,1.52c4.6,1.74,8.16,5.48,9.68,10.16
		c0.12,0.38,0.24,0.76,0.33,1.16c0.02,0.05,0.03,0.1,0.03,0.16h-30.93c-0.72,2.35-1.1,4.78-1.13,7.24c0.03,2.77,0.51,5.52,1.43,8.13
		c0.61,1.78,1.45,3.48,2.48,5.05c0.92,1.47,2.14,2.74,3.57,3.73c1.64,1.1,3.53,1.77,5.5,1.95c0.37,0.03,0.73,0.06,1.11,0.06h0.12
		c13.85-0.05,16.93-9.33,17.06-9.72l0.2-0.63L132.02,134.33z M103.19,112.19c-0.94,1.45-1.72,3-2.31,4.62h24.24
		c-0.16-1.19-0.52-2.35-1.06-3.42c-0.91-1.83-2.25-3.41-3.91-4.62c-1.94-1.47-4.3-2.28-6.73-2.33h-0.06l-0.12,0.01
		c-2.33,0.02-4.6,0.73-6.52,2.03C105.3,109.46,104.1,110.72,103.19,112.19L103.19,112.19z"
        />
        <path
          className="st0"
          d="M176.71,145.82h-12.76l0.26-0.24c1.8-1.66,2.82-4,2.82-6.45v-18.64c-0.01-7.63-2.1-13.46-7.28-13.46
		c-3.47,0.06-6.78,1.43-9.28,3.83c-1.79,1.64-3.37,3.5-4.7,5.54v22.72c0,2.45,1.02,4.79,2.82,6.45l0.26,0.24h-12.76l0.26-0.24
		c1.8-1.66,2.82-4,2.82-6.45V95.66c-0.04-2.54,0.58-5.05,1.78-7.3c0.82-1.5,1.98-2.8,3.39-3.77c1.2-0.8,2.51-1.42,3.89-1.83
		c1.67-0.49,3.4-0.74,5.14-0.73v1.32c-2.66-0.14-5.12,1.43-6.1,3.91c-0.44,1.06-0.77,2.16-0.98,3.29c-0.34,1.69-0.52,3.4-0.53,5.12
		v18.5c3.46-4.68,8.89-9.27,14.96-9.27c1-0.02,2.01,0.05,3,0.21c0.86,0.15,1.7,0.41,2.49,0.77c1.42,0.66,2.69,1.62,3.71,2.81
		c2.04,2.36,3.61,5.78,3.68,11.87v18.59c0,2.45,1.02,4.79,2.82,6.45L176.71,145.82z"
        />
        <path
          className="st0"
          d="M208.22,145.83l0.02-4.88c-1.21,0.92-2.5,1.74-3.86,2.43c-1.25,0.69-2.58,1.21-3.97,1.53
		c-0.9,0.26-1.82,0.46-2.74,0.61c-2.06,0.34-4.16,0.37-6.23,0.1c-2.14-0.26-4.19-1-6-2.17l-0.72-0.5c-0.23-0.19-0.45-0.4-0.68-0.61
		c-0.47-0.43-0.9-0.91-1.27-1.43c-0.4-0.56-0.75-1.16-1.03-1.79c-0.25-0.59-0.45-1.2-0.6-1.82c-0.61-2.4-0.43-4.93,0.51-7.21
		c0.87-2.04,2.26-3.83,4.04-5.16c1.75-1.26,3.67-2.26,5.7-2.97c2.1-0.8,4.24-1.46,6.43-1.96c9.61-2.3,9.97-5.48,9.97-5.51h0.09
		c-0.09-0.48-0.19-0.93-0.31-1.34c-0.29-1.14-0.74-2.23-1.32-3.25c-0.57-0.99-1.37-1.83-2.32-2.47c-1.36-0.84-2.93-1.27-4.53-1.23
		h-0.04c-1.98,0-3.91,0.63-5.52,1.78c-2.54,2.04-4.08,5.06-4.22,8.31c-0.05,0.59-0.94,0.9-2.17,0.82c-0.77-0.03-1.53-0.14-2.28-0.34
		c-1.17-0.35-1.84-1.36-1.48-2.16c0.89-2.01,2.13-3.85,3.67-5.42c1.5-1.54,3.35-2.69,5.39-3.36c2.15-0.65,4.39-0.97,6.64-0.95
		c1.79,0,3.57,0.18,5.32,0.56c1.45,0.3,2.85,0.81,4.16,1.51c1.58,0.88,2.91,2.15,3.87,3.69c1.4,2.32,2.12,4.98,2.07,7.69v20.79
		c0,2.45,1.02,4.79,2.82,6.45l0.26,0.24L208.22,145.83z M203.77,142.2c1.62-0.79,3.12-1.8,4.47-2.99v-20.87
		c-0.01-0.65-0.04-1.24-0.09-1.79c-1.2,1.41-3.91,3.28-10.01,4.74c-4.65,1.25-7.92,3.67-9.59,6.38c-0.76,1.22-1.22,2.61-1.37,4.04
		c-0.12,1.35-0.01,2.71,0.34,4.02c0.06,0.36,0.2,0.68,0.28,1.01c0.13,0.23,0.15,0.5,0.31,0.74c0.12,0.27,0.27,0.53,0.44,0.77
		l0.26,0.4l0.32,0.38c0.97,1.15,2.15,2.1,3.47,2.81c1.55,0.85,3.26,1.37,5.02,1.53C199.74,143.57,201.87,143.16,203.77,142.2
		L203.77,142.2z"
        />
      </g>
      <g className="st1">
        <path
          className="st2"
          d="M217.44,64.09l14.79,20.5c2.06-3.85,3.5-6.8,4.33-8.86c0.82-2.06,1.24-3.81,1.24-5.25
		c0-2.85-2.05-4.57-6.14-5.16V64.4h17.19v0.88c-1.36,0.12-2.46,0.35-3.31,0.69c-0.86,0.34-1.67,0.86-2.43,1.57
		c-1.5,1.41-3.36,4.42-5.57,9.02l-1.37,2.83c-0.74,1.59-1.81,3.67-3.23,6.23c1.12,1.47,2.44,3.06,3.96,4.75
		c1.52,1.69,2.86,3.09,4.04,4.18c1.68,1.53,3.14,2.65,4.4,3.34c1.25,0.69,2.47,1.04,3.65,1.04c1.27,0,2.33-0.35,3.18-1.06
		c0.85-0.71,1.6-1.86,2.25-3.45l0.88,0.4c-1.47,5.01-4.64,7.51-9.5,7.51c-1.62,0-3.32-0.31-5.08-0.93
		c-1.77-0.62-3.37-1.47-4.82-2.56c-2.21-1.65-4.5-3.87-6.85-6.67c-1.3,1.89-2.36,3.3-3.19,4.24c-1.71,1.92-3.71,3.38-6,4.4
		c-2.29,1.02-4.73,1.52-7.32,1.52c-2.39,0-4.7-0.44-6.92-1.33c-2.23-0.88-4.05-2.09-5.46-3.62c-2.63-2.8-3.94-6.33-3.94-10.61
		c0-4.21,1.27-8.04,3.8-11.49c1-1.35,2.2-2.63,3.6-3.82c1.4-1.19,3.32-2.58,5.77-4.18l0.71-0.49c-1.83-2.59-3.12-4.64-3.89-6.14
		c-1.8-3.48-2.7-6.58-2.7-9.32c0-1.71,0.41-3.4,1.24-5.08c1.03-2.21,2.55-3.89,4.56-5.04s4.41-1.72,7.22-1.72
		c3.66,0,6.67,0.99,9.03,2.98c2.36,1.99,3.54,4.54,3.54,7.67c0,2.3-0.61,4.34-1.82,6.12c-1.21,1.78-3.18,3.53-5.89,5.24
		C219.46,62.72,218.15,63.59,217.44,64.09z M211.06,68.03c-2.43,1.5-4.32,3.59-5.67,6.28c-1.35,2.68-2.02,5.67-2.02,8.97
		c0,2.27,0.35,4.46,1.06,6.58s1.66,3.92,2.87,5.39c1.12,1.33,2.47,2.36,4.04,3.09c1.58,0.74,3.23,1.1,4.97,1.1
		c2.74,0,5.18-0.85,7.33-2.56c0.79-0.65,1.52-1.37,2.16-2.17c0.65-0.79,1.46-1.96,2.43-3.49L211.06,68.03z M216.6,63.12
		c1.38-1.09,2.42-2.05,3.11-2.87c0.69-0.82,1.3-1.81,1.83-2.96c1.03-2.18,1.54-4.52,1.54-7.03c0-2.95-0.71-5.29-2.12-7.03
		c-1.41-1.74-3.3-2.61-5.68-2.61c-2.06,0-3.72,0.66-4.98,1.99c-1.26,1.33-1.89,3.05-1.89,5.17c0,1.27,0.26,2.53,0.77,3.8
		s1.49,3,2.93,5.21C213.49,58.92,214.98,61.03,216.6,63.12z"
        />
      </g>
    </SvgWrapper>
  );
};

export default Name;
const SvgWrapper = styled.svg`
  width: 100%;
`;
