import * as React from "react";
import styled from "styled-components";
import bg from "./assets/img/bg-address.png";
import loc from "./assets/img/loc.png";

const Address = () => {
  return (
    <Wrapper>
      <Row>
        <ColLeft className="ColLeft">
          <DateWrapper>
            <Date>Friday 13 september</Date>
            <Day>Reception</Day>
          </DateWrapper>
          <ButtonWrapper target="_blank" href="https://www.google.com/maps/place/Al+Saj+Gravity+hall/@8.5758521,76.868894,19.75z/data=!4m14!1m7!3m6!1s0x3b05bf001fd7dac7:0xbbd4b64ab9c555df!2sAl+Saj+Gravity+hall!8m2!3d8.5757473!4d76.869231!16s%2Fg%2F11vx6p4_9c!3m5!1s0x3b05bf001fd7dac7:0xbbd4b64ab9c555df!8m2!3d8.5757473!4d76.869231!16s%2Fg%2F11vx6p4_9c?hl=en-IN&entry=ttu" className="ButtonWrapper">
            <Img src={loc} />
            Get Direction
          </ButtonWrapper>
        </ColLeft>
      </Row>
      <Footer>
        <Text>
          <H4 className="h4">The Beginning of Forever</H4>
        </Text>
      </Footer>
    </Wrapper>
  );
};
export default Address;
const Wrapper = styled.div`
  align-items: center;
  position: relative;
  height: 100dvh;
  padding: 0;
  &::before {
    background-image: url(${bg});
    background-repeat: no-repeat;
    background-position: top right;
    background-size: cover;
    position: absolute;
    height: 110%;
    width: 100%;
    content: "";
    z-index: 0;
    top: -10%;
  }

  @media (min-width: 300px) and (max-width: 575px) {
    background-color: #f2f2f0;
    .ColLeft {
      width: 100%;
    }
    .ColLeft {
      position: relative;
    }

    .name {
      max-width: 225px;
      margin: 0 auto;
      display: block;
    }
    .ButtonWrapper {
      padding: 7px 16px 7px 12px;
      font-size: 12px;
    }
  }
  @media (min-width: 100px) and (max-width: 650px) {
    .h4 {
      letter-spacing: clamp(0.4rem, 2vw, 20rem);
      font-size: clamp(0.5rem, 5vw, 15rem);
    }
  }
`;
const Row = styled.div`
  justify-content: center;
  align-items: center;
  position: relative;
  flex-wrap: wrap;
  display: flex;
  height: 100dvh;
  width: 100%;
`;
const ColLeft = styled.div`
  text-align: center;
`;
const DateWrapper = styled.div`
  padding: 0 0 30px 0;
  right: 10%;
  top: 10%;
`;

const Date = styled.div`
  font-size: clamp(1rem, 1.2vw, 1.4vw);
  text-transform: uppercase;
  color: #0e9aa8;
  font-weight: 700;
`;
const Day = styled.div`
  font-size: clamp(1rem, 1.2vw, 1.4vw);
  font-weight: 500;
  color: #909d84;
`;
const Img = styled.img`
  margin: 0 5px 0 0;
`;
const ButtonWrapper = styled.a`
  border: solid 1px #a7b59b;
  padding: 9px 25px 9px 20px;
  letter-spacing: 0.4px;
  text-decoration: none;
  display: inline-flex;
  white-space: nowrap;
  border-radius: 30px;
  align-items: center;
  color: #909d84;
  font-weight: 600;
  cursor: pointer;
  &:hover{
    background: #909d84;
    color: #ffffff;
    img{
      filter: brightness(2);
    }
  }
`;

const Footer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
`;
const Text = styled.div`
  padding: 0 0 50px 0;
`;
const H4 = styled.h4`
  letter-spacing: clamp(1rem, 2.5vw, 84rem);
  font-size: clamp(1rem, 4vw, 25rem);
  text-transform: uppercase;
  white-space: nowrap;
  text-align: center;
  color: #909d84;
  margin: 0;
`;
