import './App.css';
import Home from './Home';

function App() {
  return (
       <Home/>
  );
}

export default App;
